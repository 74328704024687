import Custom500 from './500';
import { Metadata, PageContainer, CMSModule } from '@/components';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import nextI18nConfig from '../next-i18next.config';
import { setPageId, setRobot, wrapper, setHideNavigation, setNoHeroModuleBgColor } from '@/store/index';
import Header from '@/modules/Header';
import SmoothScroller from '@/modules/SmoothScroller';
import FooterVertical from '@/modules/FooterVertical';
import Script from 'next/script';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

const Index = ({
  errorCode,
  errorMessage,
  metadata,
  moduleContent,
  footer,
  header,
  homeHeroAnimationCookie,
  isRobot,
}) => {
  const router = useRouter();

  useEffect(() => {
    if (router.asPath.includes('#')) {
      const hash = router.asPath.split('#')[1];

      if (hash.length > 1) {
        const element = document.getElementById(hash);

        if (element) {
          setTimeout(() => {
            window.scrollTo({
              top: element.offsetTop,
              behavior: 'smooth',
            });
          }, 100);
        }
      }
    }
  }, [router]);

  if (errorCode) {
    return <Custom500 errorCode={errorCode} errorMessage={errorMessage} />;
  }

  // console.log(footer);

  return (
    <>
      {metadata && <Metadata metadata={metadata} isRobot={isRobot} />}
      {header && (
        <Header
          header={header}
          metadata={metadata}
          filledHeader={moduleContent && moduleContent[0] && moduleContent[0].moduleName !== 'HeroCenterAlignedModule'}
        />
      )}
      <SmoothScroller>
        <PageContainer>
          <div className="page-container-content">
            {moduleContent?.map((module) => {
              if (module.moduleName === 'HomeHeroModule') {
                return (
                  <CMSModule
                    key={module.moduleId}
                    module={module}
                    logo={header?.logoElement.logo}
                    animationCookie={homeHeroAnimationCookie}
                  />
                );
              }

              return <CMSModule key={module.moduleId} module={module} />;
            })}
          </div>
          {footer && <FooterVertical data={footer} />}
        </PageContainer>
      </SmoothScroller>
      <Script
        src="https://sdk.selfbook.com/selfbook.js?hotelId=9403&apiKey=0xoQGFM9rk5AT2UP-lDc9-rFKUKEsyTDBaM"
        id="selfbook_jssdk"
        defer
      />

      {/* <StyledCursor /> */}
    </>
  );
};

export default Index;

export const getServerSideProps = wrapper.getServerSideProps((store) => async (context) => {
  const { resolvedUrl, locale, defaultLocale, res, req } = context;

  // workaround for missing assets spamming the API
  if (resolvedUrl.startsWith('/_next/')) {
    res.statusCode = 404;
    res.end();
  }

  let errorCode,
    errorMessage = null;

  const { cookies } = req;

  try {
    const dataRes = await fetch(
      `${process.env.RESOURCE_PATH}api${defaultLocale !== locale ? '/' + locale : ''}${resolvedUrl}`,
      {
        headers: { 'x-cdn-load': req.headers['x-cdn-load'], membership: cookies.membership },
        keepalive: true,
        //redirect: 'manual',
      },
    );

    errorCode = dataRes?.ok ? false : dataRes?.status;

    const contentType = dataRes?.headers.get('content-type');
    if (errorCode === 301) {
      res.writeHead(301, {
        Location: dataRes?.headers.get('location'),
      });
      res.end();
    } else if (errorCode === 500) {
      errorMessage =
        process.env.NODE_ENV === 'development'
          ? 'BE Internal Server Error'
          : "An error has occurred and we're working to fix it.";
    } else if (!contentType || contentType.indexOf('application/json') === -1) {
      if (errorCode === 404) {
        errorMessage = 'Page not found';
        res.statusCode = 404;
      } else {
        errorCode = 500;
        errorMessage =
          process.env.NODE_ENV === 'development'
            ? 'BE response not JSON Object'
            : "An error has occurred and we're working to fix it.";
        res.statusCode = 500;
      }
    } else if (!errorCode || errorCode === 404) {
      const data = await dataRes.json();

      if (data?.metadata?.pageType === 'sp404PageNotFound' && res) {
        res.statusCode = 404;
      }
      const isRobot = !!req.headers['is-robot'];
      store.dispatch(setRobot(!!req.headers['is-robot']));

      res.setHeader(
        'Cache-Control',
        `public, max-age=${resolvedUrl.includes('isPreview=true') || data?.header === null || cookies.membership !== undefined ? 0 : isRobot ? 2592000 : process.env.CDN_CACHE_MAX_AGE || 86400}`,
      );

      const homeHeroAnimationCookie = cookies.animation_cookie;

      if (data?.metadata) {
        store.dispatch(setPageId(data.metadata.pageId));

        if (!homeHeroAnimationCookie) {
          store.dispatch(setHideNavigation(data.metadata.pageType === 'homePage'));
        }
      }

      const translations = await serverSideTranslations(locale, ['common'], nextI18nConfig);

      if (data?.moduleContent && data.moduleContent.length > 0) {
        if (data.moduleContent[0].moduleName === 'NoHeroModule') {
          store.dispatch(setNoHeroModuleBgColor(data.moduleContent[0].props.backgroundColour));
        } else {
          store.dispatch(setNoHeroModuleBgColor(null));
        }
      }

      return {
        props: {
          ...data,
          ...translations,
          homeHeroAnimationCookie: homeHeroAnimationCookie ? homeHeroAnimationCookie : null,
          isRobot: !!req.headers['is-robot'],
        },
      };
    }
  } catch (error) {
    errorCode = 500;
    console.error(error);
    errorMessage = error.message;
  }

  res.statusCode = 500;
  return {
    props: { errorCode, errorMessage },
  };
});
